
import React, { Component } from 'react';
import { AppRelevantDataContext} from '../../AppContext';
import axios from 'axios';
import App from '../../App';
import { getAPIHostURL } from '../../ClientConfig';
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import { PVG_PRODUCTION_SUPERVISOR, PVG_GENERAL_USER, PVG_DEVC_OWNER, PVG_DEVC_VIEWER, PVG_DEVC_VIEWER_WITH_SETTING, PVG_INST_PRIMARY_OWNER, PVG_INST_ADDITIONAL_VIEWER, PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING, PVG_TREE_NODE_VIEWER, PVG_ADMIN, DEVICE_MENU_ROUTES, PVG_FWMR_ADMIN } from '../../VcConstants';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import VcTopNav from './DeviceHome/VcTopNav';
import VcSideNav from './DeviceHome/VcSideNav';
import VcDevice from './VcDevice';
import VcSettings from './VcSettings';
import VcSearchBox from './DeviceHome/VcSearchBox';
import '../CSS/DeviceHome.css';
import VcChart from './VcChart';
import VcAlerts from './VcAlerts';
import VcDeviceTree from './VcDeviceTree'
import { GrMenu } from 'react-icons/gr';
import { MdClose } from 'react-icons/md';
import vilisoLogoTransparent from './../IMAGES/vilisoLogoTransparent.png'
import VcAllDeviceData from './VcAllDeviceData';
import VcTreeDefinition from './VcTreeDefinition';
import { IDS_Hide, IDS_Show } from '../../VcLanguage';
import { RiMenuUnfoldLine } from "react-icons/ri";

/* eslint-disable react/no-direct-mutation-state */

class VcDeviceHome extends Component {
    constructor(props) {
        super(props)
        this.handleResize = this.handleResize.bind(this);
    
        this.state = {
            PrivilegeEncKey: "",
            objPrivilege: {},
            arrMergedRoutesBasedOnLoggedInUsrPvg: [],
            errors: {
                others: "",
            },
            deviceTreeVisible: true,
            showDrawer: false,
            showTreeOnMobile: window.innerWidth < 600,
            windowWidth: window.innerWidth,
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);

        let appRelevantDataContextValue = this.context;
    
        if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
          if ( appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage() == false ) {
            if (this.props.history == null || this.props.history.length <= 0) {
                window.location.replace('/');
            } else {
                this.props.history.replace('/');
            }
          }
        } else {
            this.checkPrivilegeOfLoggedInUser();
        }
    }

    componentDidUpdate () {
        let currentState = this.state;
        let appRelevantDataContextValue = this.context;  // Get all the relevant data from AppContext

        if( appRelevantDataContextValue == null ||
            ("loggedInUserInfo" in appRelevantDataContextValue) == false ||
            appRelevantDataContextValue.loggedInUserInfo == null ||
            ("userID" in appRelevantDataContextValue.loggedInUserInfo) == false ||
            appRelevantDataContextValue.loggedInUserInfo.userID == null ||
            appRelevantDataContextValue.loggedInUserInfo.userID.length <= 0
        ) {
            console.log("VcAdminHome:componentDidUpdate - First time render. AppRelevantDataContext does not have LoggedIn User information or Information about the Node to be Selected.");
            return; // Do not process further
        }
    
        if(appRelevantDataContextValue.loggedInUserPrivilege.Privilege != currentState.EncryptedPvg) {
          // on Refresh values stored in LocalStorage doesnot refelect into appcontext hence 
          // we need to call componenentDidUpdate which again take privilege from context and store into state.
          this.checkPrivilegeOfLoggedInUser();
        }   
    }

    toggleDeviceTreeVisible = () => {
        this.setState({...this.state, deviceTreeVisible: !this.state.deviceTreeVisible})
    }

    allDeviceDataDeviceTreeToggle = () => {
        this.setState({...this.state, deviceTreeVisible: false})
    }

    // To set tree visible after clicking from all device data - from R-s
    dashboardDeviceTreeToggle = () => {
        this.setState({...this.state, deviceTreeVisible: true})
    }

    checkPrivilegeOfLoggedInUser = () => {
        let modifiedState = this.state;
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;  

        let encryptedPrivileges = appRelevantDataContextValue.loggedInUserPrivilege.Privilege;
        modifiedState.EncryptedPvg = encryptedPrivileges;
        modifiedState.selectedTreeNodeID = appRelevantDataContextValue.selectedNodeInfo.nodeID;
        modifiedState.selectedTreeNodeTitle = appRelevantDataContextValue.selectedNodeInfo.nodeTitle;  
        modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        let trackedDeviceInfoArr = appRelevantDataContextValue.devicesToTrack.DevicesInfo;
        let ownerOfTrackedDevices = appRelevantDataContextValue.devicesToTrack.ownerOfTrackedDevices;
        let bShowMenuBasedOnTracedDevcsKeyValue =  ((trackedDeviceInfoArr != null && trackedDeviceInfoArr.length > 0) || (ownerOfTrackedDevices != null && ownerOfTrackedDevices.length > 0)) ? true : false;  

        axios.post(`${getAPIHostURL()}/wclient/getEncChaabi`)
        .then(response =>{
            if(response.data.code == 'SUCCESS') {
               if(response.data.retrievedEncChaabi == null || response.data.retrievedEncChaabi.length <= 0) {
                    modifiedState.errors.others = `Unable to get encryption key.`;
               } else {
                   modifiedState.PrivilegeEncKey = response.data["retrievedEncChaabi"][0]["PassKey"];
                   let bytes  = aes.decrypt(encryptedPrivileges.toString(), modifiedState.PrivilegeEncKey);
                   let strPrivilege = bytes.toString(enc);

                   try {
                        modifiedState.objPrivilege = JSON.parse(strPrivilege);
                        let arrLoggedInUsrPvg = [];
                        for (const [key, value] of Object.entries(modifiedState.objPrivilege)) {
                            if(value == true) {
                                arrLoggedInUsrPvg.push(key);
                            }
                        }

                        modifiedState.arrMergedRoutesBasedOnLoggedInUsrPvg = [];

                        for(let i = 0; i < arrLoggedInUsrPvg.length; i++ ) {
                            if(arrLoggedInUsrPvg[i] == PVG_GENERAL_USER || arrLoggedInUsrPvg[i] == PVG_DEVC_OWNER || arrLoggedInUsrPvg[i] == PVG_DEVC_VIEWER || arrLoggedInUsrPvg[i] == PVG_DEVC_VIEWER_WITH_SETTING || arrLoggedInUsrPvg[i] == PVG_INST_PRIMARY_OWNER || arrLoggedInUsrPvg[i] == PVG_INST_ADDITIONAL_VIEWER || arrLoggedInUsrPvg[i] == PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING || arrLoggedInUsrPvg[i] == PVG_TREE_NODE_VIEWER || arrLoggedInUsrPvg[i] == PVG_ADMIN || arrLoggedInUsrPvg[i] == PVG_FWMR_ADMIN || arrLoggedInUsrPvg[i] == PVG_PRODUCTION_SUPERVISOR || bShowMenuBasedOnTracedDevcsKeyValue)  {
                                modifiedState.arrMergedRoutesBasedOnLoggedInUsrPvg.push(...DEVICE_MENU_ROUTES);
                            } else {
                                continue;
                            }
                        }

                   } catch(e) {
                        console.log(`Should not happen. The Privilege obtained from Context is in invalid JSON format.`);
                   }
               }

            } else {
                if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = 'Server experiencing issues.\n Try again later.';
                } else {
                    console.log("Should not reach here");
                    modifiedState.errors.others = 'Server experiencing issues.\n Try again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch(err => {
            console.log("Network error");
            console.log(err);
            if (axios.isCancel(err)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues. \n Check your Internet and Try again later.';
                console.log(modifiedState.errors.others);
                this.setState(modifiedState);
            }
        }) 
    }

    setTreeOnMobile = (val) => {
        this.setState({...this.state, showTreeOnMobile : val})

    }

    componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    }
    
    handleResize = () => {
        if ((window.innerWidth <= 570 && this.state.windowWidth > 570) || (window.innerWidth > 570 && this.state.windowWidth <= 570)) {
            this.setState({ ...this.state, windowWidth: window.innerWidth });
        } 
    }
    getDevicePath = () => {
        let fullPath = this.context.selectedNodeInfo?.nodePath;
        let subPaths;

        if(fullPath != undefined) {
            subPaths = fullPath.split('>');
        }
        
        if(subPaths != null && subPaths.length == 1 && subPaths[0] == '') {
            return '';
        }

        fullPath = '';
        subPaths?.forEach(path => fullPath += path + ' > ');
        return fullPath;
    }
    
    render() {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
            return App.renderLoadingPage();
        }

        let urlPathName = window.location.pathname;
        let showComponentBasedOnRoutes = this.state.arrMergedRoutesBasedOnLoggedInUsrPvg.includes(urlPathName) ? true : false;

        // Getting the Context value for the invokedLoginPgInfo, loginPagePath and showNextPagePathAfterLogin.
        let invokedLoginPgInfo = appRelevantDataContextValue.invokedLoginPgInfo;
        let loginPagePath = invokedLoginPgInfo.loginPagePath;
        let showNextPagePathAfterLogin = invokedLoginPgInfo.showNextPagePathAfterLogin;
        let bHideBasedOnLoginPathKeyValue =  ((invokedLoginPgInfo != null && Object.keys(invokedLoginPgInfo).length > 0) &&
          (loginPagePath != null && loginPagePath.length > 0) && (showNextPagePathAfterLogin != null 
            && showNextPagePathAfterLogin.length > 0)) ? true : false;
        let loggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        let excludeGlobalSearch = ["/device/allDevices", "/device/addNode"];

        let fullPath = this.getDevicePath();

        return (
            this.state.arrMergedRoutesBasedOnLoggedInUsrPvg.length <= 0 ? 
                <div > 
                    <p className = "customerTableHeading" style={{color:"var(--primaryColor)"}}>
                        Please Wait.
                    </p> 
                </div>

                :

                showComponentBasedOnRoutes === true && bHideBasedOnLoginPathKeyValue === false ?
                <div className='relative w-100  mainDeviceHome' style={{minHeight:'100vh'}}>
                    {(this.state.windowWidth <= 570) 
                        ?
                        <div className='px-2 py-1'>  
                            <div  className={`drawerDiv ${this.state.showDrawer ? 'drawerDivIn' : 'drawerDivOut'}`}>
                                    <VcSideNav />
                                    <FaArrowAltCircleLeft 
                                        className="closingArrow" onClick={()=> this.setState({...this.state , showDrawer : false})}
                                    />
                            </div>
                            
                            <div className='bg-white shadow-sm flex align-items-center  justify-between px-2 py-1 ' style={{borderRadius:"0.75rem"}}>
                                <div className='' style={{width:"60px"}}>
                                    <img src={vilisoLogoTransparent} alt="" style={{maxWidth:"100%"}} />
                                </div> 

                                <div className='gap-2 flex-center'>
                                    <VcTopNav showTreeOnMobile={this.state.showTreeOnMobile} setTreeOnMobile={this.setTreeOnMobile}/>
                                    <div className="tooltipPar">
                                        {this.state.showDrawer ? 
                                        <MdClose className='transition-all' onClick={()=> this.setState({...this.state , showDrawer : false})}/>:
                                        <RiMenuUnfoldLine onClick={()=> this.setState({...this.state , showDrawer : true})} />
                                        }
                                        <span className="tooltiptextbottom">
                                            {this.state.showDrawer ? t(IDS_Hide) : t(IDS_Show)}  
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {!excludeGlobalSearch.includes(urlPathName) && <VcSearchBox />}
                            
                            {
                                this.state.showTreeOnMobile ?
                                    <div className="w-100 position-relative desktopViewLayout">
                                        <VcDeviceTree setTreeOnMobile={this.setTreeOnMobile} />
                                    </div> :
                                    <div className="w-100" style={{overflowX:"scroll"}}>
                                        <Routes>
                                            <Route path="/allDevices" 
                                                element={<VcAllDeviceData dashboardDeviceTreeToggle={this.dashboardDeviceTreeToggle} fullPath={fullPath}/>} 
                                            />

                                            <Route path="/addNode"
                                                element={<VcTreeDefinition loggedInUserID= {loggedInUserID} InvokedFromDeviceTree={"deviceTree"} />}
                                            />

                                            <Route path="/dashboard" 
                                                element={<VcDevice fullPath={fullPath}/>} 
                                            />

                                            <Route path="/settings" 
                                                element={<VcSettings fullPath={fullPath}/>} 
                                            />

                                            <Route path="/charts" 
                                                element={<VcChart fullPath={fullPath}/>} 
                                            />

                                            <Route path="/alerts"
                                                element={<VcAlerts fullPath={fullPath}/>}
                                            />
                                        </Routes> 
                                    </div>
                            } 
                        </div>
                        :
                        <div className='w-100 d-flex' >
                            <div className="shadow sideNav" >
                                <VcSideNav  toggleDeviceTreeVisible={this.toggleDeviceTreeVisible}/>
                            </div>
                            <div className='treeAndContent'>   
                                <div className='flex px-1 desktopView' style={{position:"relative"}}>
                                    {(urlPathName != "/device/allDevices") &&
                                        <div style={{position:"sticky"}} className={`desktopViewLayout ${this.state.deviceTreeVisible ? 'treeVisible ' : 'treeHidden '} `}>
                                            <VcDeviceTree />
                                        </div>
                                    }

                                    <div 
                                        className={` ${(this.state.deviceTreeVisible && urlPathName != "/device/allDevices")? 'contentWithTree ' : 'contentWithoutTree '} relative`}
                                        style={{ maxWidth:'100%'}}
                                    >
                                        <div className='bg-white shadow-sm flex items-center  justify-between px-2 py-2' style={{ borderRadius:"0.75rem" }}>
                                            {
                                                urlPathName == "/device/allDevices" ? 
                                                    <div className='' style={{width:"60px"}}></div> :
                                                    <VcSearchBox/> 
                                            }

                                            <VcTopNav toggleDeviceTreeVisible={this.allDeviceDataDeviceTreeToggle} dashboardDeviceTreeToggle={this.dashboardDeviceTreeToggle} />
                                        </div>

                                        <Routes>
                                            <Route path="/allDevices" 
                                                element={<VcAllDeviceData dashboardDeviceTreeToggle={this.dashboardDeviceTreeToggle} fullPath={fullPath}/>} 
                                            />

                                            <Route path="/addNode"
                                                element={<VcTreeDefinition loggedInUserID= {loggedInUserID} InvokedFromDeviceTree={"deviceTree"} />}
                                            />

                                            <Route path="/dashboard" 
                                                element={<VcDevice key = "/dashboard" fullPath={fullPath}/>} 
                                            />

                                            <Route path="/settings" 
                                                element={<VcSettings key = "/settings" fullPath={fullPath}/>} 
                                            />

                                            <Route path="/charts" 
                                                element={<VcChart key = "/charts" fullPath={fullPath}/>} 
                                            />

                                            <Route path="/alerts"
                                                element={<VcAlerts key = "/alerts" fullPath={fullPath}/>}
                                            />
                                        </Routes> 
                                    </div>
                                </div>
                            </div>
                        </div> 
                    }
                </div>

                :
                
                <div>
                    <Routes>  
                        <Route path="/:id" 
                            element={<p className = "customerTableHeading" key = "/:id" style={{color:"var(--errorColor)"}}>
                                The URL is invalid or you do not have privilege to access it.</p>}
                        /> 
                    </Routes>  
                </div> 
        )
    }
}

VcDeviceHome.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcDeviceHome;