
import React, { Component } from 'react';
import { AppRelevantDataContext } from '../../AppContext';
import '../CSS/NotificationSetting.css';
import classnames from 'classnames';
import "react-tabs/style/react-tabs.css";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import axios from 'axios';
import { CO, O3, LPG, CH4, CO2, HUM, NO2, VOC, PM10, PM25, TEMP, PM1, NH3, H2S, SO2, NH3OD, NO_DEVC_FOUND_FOR_LOGGED_IN_USER, SMOKE, PROD_DEVC_OWNER_USER_ID, VRI, MRI, DEVICE_TYPE_PFC, WLIP, SLIP, AWL, VOL, AWC, WATER_LEVEL_INDICATOR, SATISFACTORY_L, SATISFACTORY_U, WD, LUX, RNFL, HCHO, O2, PM100, NO, CL, CH3SH, NOISE, UV, RADON, AP, WS, DLV, TCWL} from '../../VcConstants';
import { getAPIHostURL } from '../../ClientConfig';
import VcSlider from './VcSlider';
import VcRange from './VcRange';
import { LOW, LOW_SEVERE_L, LOW_SEVERE_U, GOOD_L, GOOD_U, MODERATE_L, MODERATE_U, POOR_L, POOR_U, V_POOR_L, V_POOR_U, HIGH_SEVERE_L, HIGH_SEVERE_U, MAX , AQI, CAQI} from '../../VcConstants';
import {ARR_LEVEL_BASED_PARAMS, ARR_DEFAULT_SETTING_LEVELS, MAP_DEFAULT_LEVEL_SETTING_LONG_DESCRIPTION, MAP_DEFAULT_LEVEL_SETTING_SHORT_DESCRIPTION, MAP_NH3OD_LEVEL_SETTING_SHORT_DESCRIPTION} from '../../VcConstants';
import { IDS_AlertSetting, IDS_RangeSetting, IDS_EnableAlerts, IDS_ShowNotification, IDS_AlertOnEnableShowNotification, IDS_AlertOnEnableAlertNotification, IDS_HierarchicalAlertSetting,
        IDS_nitrogenDioxide, IDS_Ozone, IDS_sulphurDioxide, IDS_TotalVolatileOrgComp, IDS_carbonMonoxide, IDS_LPG,
        IDS_Ammonia, IDS_carbonDioxide, IDS_hydrogenSulphide, IDS_Methane, IDS_Dust, IDS_Temperature, IDS_Humidity,
        IDS_Odour, IDS_TVOC, IDS_Min, IDS_Max, IDS_Value, IDS_Update, IDS_SettingsModelInfoNotFound,
        IDS_SrvrIssueAlertSettNotFound, IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_SrvrIssueDevcModelInfoNotFound,
        IDS_AlertSettNotAllowed, IDS_SrvrIssueNotRecDevcIDOrLogUserID, IDS_AUSrvrIssueReqParamsNotSent, IDS_ChangeSaveSuccessfully, 
        IDS_GeneralUser, IDS_ReadOnlyAlertSettingMsg1, IDS_ReadOnlyAlertSettingMsg2, IDS_SMOKE, IDS_DefInfoMsgForTreeNodeInSettingPg, 
        IDS_InfoMsgForTreeNodeWhileChildDevicesMissingInSettingPg, IDS_InfoMsgForTreeNodeWhileChildNodesMissingInSettingPg,
        IDS_InfoMsgForTreeNodeMissingInSettingPg, IDS_VRI, IDS_MRI, IDS_Index, IDS_InfoMsgForPFCDevicesInSettingPg, 
        IDS_DevcBlockedDueToNonPaymentOfSomeCost, IDS_WLIP, IDS_SLIP , IDS_airQualityIndex, IDS_currentAirQualityIndex,
        IDS_NH3ODRangeAlertLevel2SD,
        IDS_NH3ODRangeAlertLevel3SD,
        IDS_NH3ODRangeAlertLevel4SD,
        IDS_NH3ODRangeAlertLevel5SD,
        IDS_RangeAlertLevel2LD,
        IDS_RangeAlertLevel3LD,
        IDS_RangeAlertLevel4LD,
        IDS_RangeAlertLevel5LD,
        IDS_Reset,
        IDS_AlertRangePopUpInfo,
        IDS_AlertValuePopUpInfo,
        IDS_RangeAlertLevel1LD,
        IDS_NH3ODRangeAlertLevel1SD,
        IDS_ResetSaveSuccessfully,
        IDS_Formaldehyde,
        IDS_Oxygen,
        IDS_PM100,
        IDS_NitricOxide,
        IDS_Chlorine,
        IDS_MethylMercaptan,
        IDS_Noise,
        IDS_LightIntensity,
        IDS_UV,
        IDS_Radon,
        IDS_AirPressure,
        IDS_WindSpeed,
        IDS_WindDirection,
        IDS_DaylightVisibility,
        IDS_Rainfall,
        IDS_EnableAlertsForParam,
        IDS_ResetDefaultTitle,
        IDS_AlertValuePopUpInfoForReverseSlider,
        IDS_FormatterForMinMax,
        IDS_FormatterForValueTag,
        IDS_FormatterForReverseValueTag,
        IDS_ResetSettingsConfirmation,
        IDS_UpdateSettingsConfirmation,
        IDS_RangeAlertLevel6LD,
        IDS_NH3ODRangeAlertLevel6SD} from '../../VcLanguage';
import VcSetupDevice from './VcSetupDevice';
import { Switch, Icon, InputNumber, Row, Col, Button } from 'antd';
import { IoCheckmark } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { Slider } from 'antd';
import { Tooltip } from 'antd';

const formatterForMinMax = (value) => {
  return `Alerts will be sent above ${value}`;
  // return t(IDS_FormatterForMinMax, value);
};

const formatterForValueTag = (value) => {
  return `Alerts will be sent from ${value}`;
  // return t(IDS_FormatterForValueTag, value);
}

const formatterForReverseValueTag = (value) => {
  return `Alerts will be sent below ${value}`;
  // return t(IDS_FormatterForReverseValueTag, value);
}

class VcNotificationSetting extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      selectedTreeNodeID: '', 
      isRootSelected: true, // Initially AllDeviceData will be rendered
      selectedTreeNodeTitle: '',  
      LoggedInUserID: '',
      activeTab: '1',
      enableAllNotifications: true,
      enableShowNotifications: true,
      enableHirarchicalAlerts: true,
      bCheckDeviceBlockedStatus: false,
      havingPvgToViewBlockedDeviceData: false,
      rangeMinMaxSetting: [
        // There will be multiple entries as per the format given below for the selected device which will come from
        // the database
        // Example Entry: {inputvalue: [null, 20], min: 0, max: 1000, step: 2, disable:true, name:'Temperature', shortName: 'Temp', isChecked: false},
      ],
      paramsForAlertEscalation: [],
      stdAirQualityRanges: [],
      stdAirQualityGoodRangeObj: {},
      deviceModelInfo: {},
      deviceModelName: "",
      NoPrivilegeForChangeSettings: false,
      isDeviceBlocked: false,
      errors: {
        others: ''     
      },
      disabled: false,
      inputValue: 1,
      updateConfirmFlag: false,
      resetConfirmFlag: false,
      retrievedModelInfo: {}, 
    };
  }

  // To toggle between Notification and Range Setting
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  
  onChangeValue = (newValue) => {
    this.setState({
      inputValue: newValue
    })
  };

  getParamFullName(inParamNameType, t){

    switch(inParamNameType) {
        case AQI:
          return t(IDS_airQualityIndex);
        case CAQI:
          return t(IDS_currentAirQualityIndex);
        case NO2:
            return t(IDS_nitrogenDioxide);
        case O3:
            return t(IDS_Ozone);
        case SO2:
            return t(IDS_sulphurDioxide);
        case VOC:
            return t(IDS_TVOC);
        case CO:
            return t(IDS_carbonMonoxide);
        case NH3:
            return t(IDS_Ammonia);
        case NH3OD:
            return t(IDS_Odour);
        case CO2:
            return t(IDS_carbonDioxide);
        case H2S:
            return t(IDS_hydrogenSulphide);
        case CH4:
            return t(IDS_Methane);
        case LPG:
            return t(IDS_LPG);
        case PM1:
            return t(IDS_Dust);
        case PM25:
            return t(IDS_Dust);
        case PM10:
            return t(IDS_Dust);
        case TEMP:
            return t(IDS_Temperature);
        case HUM:
            return t(IDS_Humidity);
        case SMOKE:
          return t(IDS_SMOKE); 
        case VRI:
          return t(IDS_VRI); 
        case MRI:
          return t(IDS_MRI); 
        case WLIP:
          return t(IDS_WLIP);
        case SLIP:
          return t(IDS_SLIP);

        
        case HCHO:
          return t(IDS_Formaldehyde); 
        case O2:
          return t(IDS_Oxygen); 
        case PM100:
          return t(IDS_PM100); 
        case NO:
          return t(IDS_NitricOxide); 
        case CL:
          return t(IDS_Chlorine); 
        case CH3SH:
          return t(IDS_MethylMercaptan); 
        case NOISE:
          return t(IDS_Noise); 
        case LUX:
          return t(IDS_LightIntensity); 
        case UV:
          return t(IDS_UV); 
        case RADON:
          return t(IDS_Radon); 
        case AP:
          return t(IDS_AirPressure); 
        case WS:
          return t(IDS_WindSpeed); 
        case WD:
          return t(IDS_WindDirection); 
        case DLV:
          return t(IDS_DaylightVisibility); 
        case RNFL:
          return t(IDS_Rainfall); 

        default:
            console.error(`Unable to get ParamFullName. Unknown Param Type: ${inParamNameType}`);
            return ""; // Return empty string
    }
  }



//****************************                  NOTIFICATION SETTING HANDLING        **********************************

  handleAllCheck = (index) => {
    
    if(this.state.enableAllNotifications) {
      // Create a copy of the array
      const updatedArray = [...this.state.rangeMinMaxSetting];

      // Update the 'isChecked' property of the clicked object
      updatedArray[index] = {
        ...updatedArray[index],
        isChecked: !updatedArray[index].isChecked
      };

      // Update state with the new array
      this.setState({ rangeMinMaxSetting: updatedArray });
    } else {
      return;
    }
  }

  handleShowNotificationCheck = (e) => {

    let appRelevantDataContextValue = this.context;  // Get all the relevant data from AppContext
    let t = appRelevantDataContextValue.t; 

    let modifiedState = this.state;

    if(modifiedState.enableAllNotifications == true){
      this.setState({
        enableShowNotifications: e.target.checked
      });
    } else{
      alert(t(IDS_AlertOnEnableShowNotification));
    }
  }

  handleCheckChildElement = (getIndex) => {
    let appRelevantDataContextValue = this.context;  // Get all the relevant data from AppContext
    let t = appRelevantDataContextValue.t; 

    // let indexOfTargateElement = e.target.getAttribute('data-arr-index');
    // let bChildElementCheckedState = e.target.checked;

    let rangeMinMaxSetting = this.state.rangeMinMaxSetting;

    rangeMinMaxSetting.map((singleCheck, index) => {
      if(index == getIndex && this.state.enableAllNotifications == true) {
        singleCheck.isChecked = !singleCheck.isChecked;
      }
    });

    if(this.state.enableAllNotifications == false){
      alert(t(IDS_AlertOnEnableAlertNotification));
    }

    this.setState({
      rangeMinMaxSetting: rangeMinMaxSetting
    });

  }

  componentDidMount() {

    let appRelevantDataContextValue = this.context;  // Get all the relevant data from AppContext

    // Showing loading page to user to avoid unnecessary clicks from the user while fetching data from the server.
    appRelevantDataContextValue.onChangeProcessingReq(true);

    this.getAlertSettings();
  }
   
  componentDidUpdate() {
    let currentState = this.state;
    let appRelevantDataContextValue = this.context;  // Get all the relevant data from AppContext

    if( appRelevantDataContextValue == null ||
        ("loggedInUserInfo" in appRelevantDataContextValue) == false ||
        appRelevantDataContextValue.loggedInUserInfo == null ||
        ("userID" in appRelevantDataContextValue.loggedInUserInfo) == false ||
        appRelevantDataContextValue.loggedInUserInfo.userID == null ||
        appRelevantDataContextValue.loggedInUserInfo.userID.length <= 0 ||
        ("selectedNodeInfo" in appRelevantDataContextValue) == false ||
        appRelevantDataContextValue.selectedNodeInfo == null ||
        ("nodeID" in appRelevantDataContextValue.selectedNodeInfo) == false ||
        appRelevantDataContextValue.selectedNodeInfo.nodeID == null ||
        appRelevantDataContextValue.selectedNodeInfo.nodeID.length <= 0
    ) {
      console.error("SingleDeviceData:componentDidUpdate - Should not happen. AppRelevantDataContext does not have LoggedIn User information or Information about the Node to be Selected.");
      return; // Do not process further
    }

    if( currentState.LoggedInUserID != appRelevantDataContextValue.loggedInUserInfo.userID ||
        currentState.selectedTreeNodeID != appRelevantDataContextValue.selectedNodeInfo.nodeID
    ) {
      // Showing loading page to user to avoid unnecessary clicks from the user while fetching data from the server.
      appRelevantDataContextValue.onChangeProcessingReq(true);

      // The selected tree node has changed. Update the state based on the new selected node.
      this.getAlertSettings();
    }

    if(this.state.rangeMinMaxSetting != null && this.state.rangeMinMaxSetting.length > 0) {
      const fetchSliderPointer = document.querySelectorAll("div[aria-disabled]");

      fetchSliderPointer.forEach((pointer) => {
        if(pointer.ariaDisabled === "false") {
          pointer.classList.remove("slider-disabled");
          pointer.classList.add("slider-enabled");
        } else {
          pointer.classList.remove("slider-enabled");
          pointer.classList.add("slider-disabled");
        }
      })
    }
  }

  getAlertSettings() {
    // First get the full state
    let modifiedState = this.state;
    modifiedState.rangeMinMaxSetting = []; // Initially set the alert settings array to empty
    modifiedState.stdAirQualityRanges = []; // Initially set the air quality range array to empty
    modifiedState.enableAllNotifications = true; // Initially assume all Notifications are enabled for this device
    modifiedState.enableShowNotifications = true;

    let appRelevantDataContextValue = this.context;  // Get all the relevant data from AppContext
    let t = appRelevantDataContextValue.t;    
 
    let selectedTreeNodeID = appRelevantDataContextValue.selectedNodeInfo.nodeID;
    let selectedTreeNodeTitle = appRelevantDataContextValue.selectedNodeInfo.nodeTitle;  
    let isRootSelected = appRelevantDataContextValue.selectedNodeInfo.isRoot; 
    let loggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
    let IsSelectedNodeDevice = appRelevantDataContextValue.selectedNodeInfo.isDevc;
    modifiedState.bCheckDeviceBlockedStatus = false;
    modifiedState.havingPvgToViewBlockedDeviceData = false;

    modifiedState.selectedTreeNodeID = selectedTreeNodeID;
    modifiedState.selectedTreeNodeTitle = selectedTreeNodeTitle;
    modifiedState.isRootSelected = isRootSelected;
    modifiedState.LoggedInUserID = loggedInUserID;

    modifiedState.isDeviceBlocked = false;

    // JSON object containing DeviceID to be sent to the API Server
    let jsonParams = {
      DeviceID: selectedTreeNodeID,
      LoggedInUserID: loggedInUserID
    }

    // Incase the LHS Tree does not have any devices OR if it is a General user (a user without Devices) Logged in
    // and the User has clicked the node with ID 'NO_DEVC_FOUND_FOR_LOGGED_IN_USER' or If the selected node itself is not a valid device.
    // In this case no need to bring data from server as DeviceID is not valid and we have to show him a HTML page with message.
    // Also you have to update state with loggedinUser, selectedTreeNodeID as it will avoid triggering componentDidUpdate infinitely.
    if((selectedTreeNodeID.length > 0 && selectedTreeNodeID == NO_DEVC_FOUND_FOR_LOGGED_IN_USER) || !IsSelectedNodeDevice) {
      this.setState(modifiedState);
      appRelevantDataContextValue.onChangeProcessingReq(false); // Removing Loading spinner
      return;
    }


    axios.post(`${getAPIHostURL()}/wclient/getAlertSetting`, jsonParams)
    .then(response => {
      appRelevantDataContextValue.onChangeProcessingReq(false); // Removing Loading spinner

      if(response.data.code == 'SUCCESS' || response.data.code == 'NO_ALERT_SETTING_PRIVILEGE') {

        if(response.data.code == 'SUCCESS') {
          modifiedState.NoPrivilegeForChangeSettings = false;
        } else {
          modifiedState.NoPrivilegeForChangeSettings = true;
        }

        // Fill out the Device Settings and Range Settings that were received from the server
        let receivedAlertSettings = response.data.retreivedAlertSettings;
        let receivedDeviceModelInfo = response.data.deviceModelInfo;
        let alertSettingRanges = response.data.alertSettingRanges; // Not used anymore. Old hardcoded ranges.
        let receivedAirQualityRanges = response.data.retreivedAirQualityRanges;

        // Show blocked Devices Data to Production/Support Users.
        modifiedState.bCheckDeviceBlockedStatus = response.data.bCheckDeviceBlockedStatus != null && 
          response.data.bCheckDeviceBlockedStatus == 0 ? true : false;
        modifiedState.havingPvgToViewBlockedDeviceData = response.data.havingPvgToViewBlockedDeviceData != null && 
          response.data.havingPvgToViewBlockedDeviceData > 0 ? true : false;

        modifiedState.stdAirQualityRanges = receivedAirQualityRanges;

        modifiedState.stdAirQualityRanges = receivedAirQualityRanges.map(param => ({
          ...param,
          RangeValues: JSON.parse(param.RangeValues)
        }));



        //Seperating Good ranges for all parameters
        let key;
        let value;
        let range;
        let stdAirQualityGoodRangeObj;
        try {
          receivedAirQualityRanges.forEach(parameter => {
            key = parameter.MeasuredParam
            range = JSON.parse(parameter.RangeValues)
            value = [ (key=="WLIP" || key=="SLIP") ? range.PoorU : range.GoodL, (key=="WLIP" || key=="SLIP") ? range.LowSevereL : range.GoodU]
            stdAirQualityGoodRangeObj = {...stdAirQualityGoodRangeObj, [key]:value}
          })
        } catch (error) {
          console.log('Error parsing good range values');
          stdAirQualityGoodRangeObj = {};
        }
        modifiedState.stdAirQualityGoodRangeObj = stdAirQualityGoodRangeObj;


        if(receivedDeviceModelInfo == null || 
           receivedDeviceModelInfo["MeasuredParams"] == null
        ) {
          modifiedState.errors.others = t(IDS_SettingsModelInfoNotFound);
        } else {

          try {

            // If current AlertSettings are missing on the server, they will be filled from the default settings present in the Model.
            // Instance: Device freshly added into a Model will fetch the initial data from its Model since there is no device's alert data into AlertSettings,
            // will need to update the default model data rendered initially to feed that data into AlertSettings for that particular device.
            let currAlertSettings = (receivedAlertSettings != null && 
                             receivedAlertSettings.length > 0 && 
                             receivedAlertSettings[0] != null &&
                             receivedAlertSettings[0]["MeasuredParams"] != null) ?
                            JSON.parse(receivedAlertSettings[0].MeasuredParams) : {};

            // Model however needs to have the "Sequence" and "DefaultRangeSettings" for params
            let modelInfo = JSON.parse(receivedDeviceModelInfo.MeasuredParams);

            // Store this in the state for later use
            this.state.deviceModelInfo = modelInfo;
            this.state.deviceModelName = receivedDeviceModelInfo.ModelName;

            if(modelInfo != null && modelInfo["Seq"] != null && modelInfo.Seq.length > 0) {

              // Take each MeasuredParameter and CurrAlertSetting value that was received from axios and 
              // add into the State's 'rangeMinMaxSetting' array after appropriate modification

              // Also initially set no errors message (which can/may be set to appropriate error in the loop processing if required)
              modifiedState.errors.others = '';

              // Set AllNotificationEnabled state from server (enable all in case settings not present)
              modifiedState.enableAllNotifications = 
                ( currAlertSettings["EnableAllNotifications"] != null ) ? currAlertSettings.EnableAllNotifications : true;

              let arrParamSeq = modelInfo.Seq;

              if(this.state.deviceModelName == WATER_LEVEL_INDICATOR){
                arrParamSeq = arrParamSeq.filter(function(e) { return e !== AWC && e !== VOL && e !== AWL && e != TCWL})
              }

              // EXCLUDING ALERT RANGE SETTINGS FOR THE PARAMS: Light Visibility [LUX], Wind Direction [WD], Rainfall [RNFL]
              let arrParamRanges = [];
              arrParamSeq.forEach((param) => {
                if(param != WD && param != LUX && param != RNFL) {
                  // && param != NO && param != CH4 && param != CH3SH && param != RADON
                  arrParamRanges.push(param)
                }
              })

              // let noOfSettings = arrParamSeq.length;
              let noOfSettings = arrParamRanges.length;
              let param = null;
              let singleParamAlertSetting = null, singleParamNotify = true;

              for (let i = 0; i < noOfSettings; i++) {

                // Model needs to have Ranges and Default values for settings corresponding to each param
                // param = arrParamSeq[i];
                param = arrParamRanges[i]; // -----> Retuns All Params Excluding Day Light Visibility (DLV), Rainfall (RNFL)

                if( param == null || 
                    param.length <= 0 || 
                    modelInfo[param] == null ||
                    ("RangeMin" in modelInfo[param]) == false ||
                    ("RangeMax" in modelInfo[param]) == false ||
                    ("DefMin" in modelInfo[param]) == false ||
                    ("DefMax" in modelInfo[param]) == false ||
                    ("Step" in modelInfo[param]) == false
                ) {
                  modifiedState.errors.others = t(IDS_SettingsModelInfoNotFound);
                  modifiedState.rangeMinMaxSetting = []; // Wipe out partially filled settings array in the modified state
                  break; // Do not process any further
                }

                // At the below line in singleParamAlertSetting: `currAlertSettings[param].High == 20 ? 100 : currAlertSettings[param].High`, 
                // as NH3OD has a value at Level 6 {20} & One of the Slider Diff-Value between pointers for NH3OD is also {20} arising conflict, thus, 
                // done to have unique Slider Level Renderation as per Device's Alert Data Fetched
                singleParamAlertSetting = ( (param in currAlertSettings) && 
                                            ("Low"  in currAlertSettings[param]) &&
                                            ("High" in currAlertSettings[param])
                                          ) ?
                                          [ currAlertSettings[param].Low, currAlertSettings[param].High == 20 ? 100 : currAlertSettings[param].High ] : // Use value from Current Alert Setting if present
                                          [ modelInfo[param].DefMin, modelInfo[param].DefMax ]; // Use default value from Model if not present in Current Alert Setting

                singleParamNotify = ( (param in currAlertSettings) &&
                                      ("Notify" in currAlertSettings[param]) && 
                                      (currAlertSettings[param].Notify != null)
                                    ) ?
                                    currAlertSettings[param].Notify : // Use value from Current Alert Setting if present
                                    true; // Use default value of 'true' if not present in Current Alert Setting
                if (modifiedState.enableAllNotifications == false) {
                  singleParamNotify = false; // If all notifications are disabled, ignore the individual Notify setting
                }           

                let singleMinMaxSetting = { 

                  // EXAMPLE: {inputvalue: [null, 20], min: 0, max: 1000, step: 2, disable: false, name:'Temperature', shortName: 'Temp', isChecked: false},

                  inputvalue: singleParamAlertSetting,
                  min: modelInfo[param].RangeMin,
                  max: modelInfo[param].RangeMax,
                  step: modelInfo[param].Step,
                  disable: ! (modifiedState.enableAllNotifications), // Action on each checkbox disabled if all notifications are disabled
                  name: this.getParamFullName(param, t), 
                  shortName: param,
                  isChecked: singleParamNotify,
                  AlertBased: modelInfo[param].hasOwnProperty("AlertBased") ?  modelInfo[param].AlertBased : false,
                  tooltipMin: modifiedState.stdAirQualityGoodRangeObj[param][0],
                  tooltipMax: modifiedState.stdAirQualityGoodRangeObj[param][1]
                };
                
                modifiedState.rangeMinMaxSetting.push(singleMinMaxSetting);

              } // for: Adding each setting into the State's 'rangeMinMaxSetting' array
            } else {
              modifiedState.errors.others = t(IDS_SettingsModelInfoNotFound);
            }
            
          // this.setState(modifiedState)            

          } catch(e) {
            console.log("NoficationSetting - Exception converting string to JSON for AlertSetting/DeviceModel info.")
            modifiedState.errors.others = t(IDS_SrvrIssueAlertSettNotFound);
          }

        }
      } else {
        if (response.data.code == 'REQ_PARAMS_MISSING') {
          // Let the user know that the DeviceID was not received by the Server
          modifiedState.errors.others = t(IDS_SrvrIssueNotRecDevcIDOrLogUserID);
        } else if (response.data.code == 'ERROR_GETTING_DEVC_MODEL_INFO') {
          // Tell the user that Server is experiencing errors
          modifiedState.errors.others = t(IDS_SrvrIssueDevcModelInfoNotFound);
        } else if (response.data.code == 'SQL_ERROR') {
          // Tell the user that Server is experiencing errors
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else if (response.data.code == 'DEVICE_IS_BLOCKED') {
          modifiedState.isDeviceBlocked = true;
        } else {
          console.log('Should not reach here');
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        }
      }

      // Display the Alert Settings received from the Server. Or display any specific error messages.
      this.setState(modifiedState);
    })
    .catch( error => {
      appRelevantDataContextValue.onChangeProcessingReq(false); // Removing Loading spinner

      console.log("Network error:");
      console.log(error);
      if (axios.isCancel(error)) {
        console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
      } else {
        // Tell the user that there are network issues
        modifiedState.errors.others = t(IDS_RegistNetworkError);
        this.setState(modifiedState);
      }
    }); 
      
  }

  onNotificationSubmit = (invokedFrom, e) => {
    e.preventDefault();
    let appRelevantDataContextValue = this.context;  // Get all the relevant data from AppContext
    let t = appRelevantDataContextValue.t;  

    let confirmBoxMessge = "";
    if(invokedFrom == "resetSettings") {
      confirmBoxMessge = t(IDS_ResetSettingsConfirmation);
    } else {
      confirmBoxMessge = t(IDS_UpdateSettingsConfirmation);
    }

    if((!window.confirm(confirmBoxMessge))) {
      return;
    }

    let selectedTreeNodeID = appRelevantDataContextValue.selectedNodeInfo.nodeID;
    let selectedTreeNodeTitle = appRelevantDataContextValue.selectedNodeInfo.nodeTitle;  
    let isRootSelected = appRelevantDataContextValue.selectedNodeInfo.isRoot; 
    let loggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

    let modifiedState = this.state;
    modifiedState.selectedTreeNodeID = selectedTreeNodeID;
    modifiedState.selectedTreeNodeTitle = selectedTreeNodeTitle;
    modifiedState.isRootSelected = isRootSelected;
    modifiedState.LoggedInUserID = loggedInUserID;

    const modelInfo = this.state.deviceModelInfo;

    let invokedFromResetBoolean;

    let parsedAirQualityRanges = this.state.stdAirQualityRanges;

    const singleParamAirQualityRange = parsedAirQualityRanges.find(param => param.MeasuredParam == "NH3OD") 

    let arrValueToAirQualityLevelMap = [
      { Level: "L0", LevelQuality: "LowSevere",     LRange: singleParamAirQualityRange.RangeValues[LOW_SEVERE_L],   URange: singleParamAirQualityRange.RangeValues[LOW_SEVERE_U]   },
      { Level: "L1", LevelQuality: "Good",          LRange: singleParamAirQualityRange.RangeValues[GOOD_L],         URange: singleParamAirQualityRange.RangeValues[GOOD_U]         },
      { Level: "L2", LevelQuality: "Satisfactory",  LRange: singleParamAirQualityRange.RangeValues[SATISFACTORY_L], URange: singleParamAirQualityRange.RangeValues[SATISFACTORY_U] },
      { Level: "L3", LevelQuality: "Moderate",      LRange: singleParamAirQualityRange.RangeValues[MODERATE_L],     URange: singleParamAirQualityRange.RangeValues[MODERATE_U]     },
      { Level: "L4", LevelQuality: "Poor",          LRange: singleParamAirQualityRange.RangeValues[POOR_L],         URange: singleParamAirQualityRange.RangeValues[POOR_U]         },
      { Level: "L5", LevelQuality: "VPoor",         LRange: singleParamAirQualityRange.RangeValues[V_POOR_L],       URange: singleParamAirQualityRange.RangeValues[V_POOR_U]       },
      { Level: "L6", LevelQuality: "HighSevere",    LRange: singleParamAirQualityRange.RangeValues[HIGH_SEVERE_L],  URange: singleParamAirQualityRange.RangeValues[HIGH_SEVERE_U]  }
    ];

    let jsonAllData = {
      DeviceID: selectedTreeNodeID,
      SetByUser: loggedInUserID,
      EnableAllNotifications: modifiedState.enableAllNotifications,
      EnableShowNotifications: modifiedState.enableShowNotifications,
      allData: []
    };

    this.state.rangeMinMaxSetting.forEach( (singleValue) => {
      const singleParamModelInfo = this.state.deviceModelInfo[singleValue.shortName]

      let odourValueOnSubmission;

      // Below, have used both values URange as well as the difference value between each pointer on the slider to appropriately match the Alert Description
      // The reason behind using both values for match-checking is because, at initial component mount, it fetches the `URange` of the Parameter NH3OD, but the slider
      // will always take its difference value when slider is moved, thereby, need of matching paramValue with Slider's Diff-Value
      if(singleValue?.shortName == NH3OD && singleValue?.inputvalue[1]) {
        if(singleValue?.inputvalue[1] == 20) {
          odourValueOnSubmission = arrValueToAirQualityLevelMap[2].LRange
        } else if(singleValue?.inputvalue[1] == 40) {
          odourValueOnSubmission = arrValueToAirQualityLevelMap[3].LRange
        } else if(singleValue?.inputvalue[1] == 60) {
          odourValueOnSubmission = arrValueToAirQualityLevelMap[4].LRange
        } else if(singleValue?.inputvalue[1] == 80) {
          odourValueOnSubmission = arrValueToAirQualityLevelMap[5].LRange
        } else {
          odourValueOnSubmission = arrValueToAirQualityLevelMap[6].LRange
        }
      }

      let singleSetting = {  
        Name: singleValue.name, 
        MeasuredParam: singleValue.shortName, 
        Notify: (singleParamModelInfo.hasOwnProperty("AlertBased") || singleValue.isChecked) ? 1 : 0,
        Low: invokedFrom == "resetSettings" ? singleParamModelInfo.DefMin : singleValue.inputvalue[0],
        High: invokedFrom == "resetSettings" ? singleParamModelInfo.DefMax : (singleValue.shortName == NH3OD) ? odourValueOnSubmission : singleValue.inputvalue[1], 
        Min: singleValue.min, 
        Max: singleValue.max, 
        Step: singleValue.step
      };
      jsonAllData.allData.push(singleSetting);
    });

    
    axios.post(`${getAPIHostURL()}/wclient/updateAlertSetting`, jsonAllData)
      .then(response => {
        if(response.data.code == 'SUCCESS'){
          if(invokedFrom == "resetSettings") {
            alert(t(IDS_ResetSaveSuccessfully));

            this.getAlertSettings();

          } else {
            alert(t(IDS_ChangeSaveSuccessfully));
          }
        } else {
          if (response.data.code == 'REQ_PARAMS_MISSING') {
            // Let the user know that the DeviceID was not sent to the Server
            modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
          } else if (response.data.code == 'NO_ALERT_SETTING_PRIVILEGE') {
            // Tell the user that he does not have privileges to perform Alert Settings
            modifiedState.errors.others = t(IDS_AlertSettNotAllowed);
          } else if (response.data.code == 'SQL_ERROR') {
            // Tell the user that Server is experiencing errors
            modifiedState.errors.others = t(IDS_LoginServerIssue);
          } else {
            console.log('Should not reach here');
            modifiedState.errors.others = t(IDS_LoginServerIssue);
          }
          this.setState(modifiedState);
          alert(modifiedState.errors.others);
        }  
      })
      .catch( error => {
        console.log("Network error:");
        console.log(error);
        if (axios.isCancel(error)) {
          console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
          // Tell the user that there are network issues
          modifiedState.errors.others = t(IDS_RegistNetworkError);
          this.setState(modifiedState);
          alert(modifiedState.errors.others);
        }
      }); 
  }


  //****************************          RANGE SETTING HANDLING        **********************************
   
  // Function converts levels (like 'L1', 'L2' etc.) to their appropriate
  // upper and lower range values for the specified param (example: 'NH3OD').
  // If level could not be converted, then uses the range values from
  // 'Good' range for the param. If 'Good' range not found, then uses value 
  // zero for both upper and lower range.
  convertLevelToRangeForSpecifiedParam = (instrParamShortName, instrLevel) => {

    // In case of Level Based settings (Example: 'NH3OD'), first convert the 
    // value which is a Level to numeric value before saving in state
    let objConvertedValue = null;

    objConvertedValue = this.getAirQualityRangeValuesForParamAndLevel(instrParamShortName, instrLevel);

    if(objConvertedValue.LRange == null && objConvertedValue.URange == null) {

      // If for any reason, the Level was not mapped to range value, then use
      // the upper and lower limits of 'Good' range for this parameter (so that
      // notifications will be generated when the good limits are crossed)
      objConvertedValue = this.getAirQualityRangeValuesForParamAndLevelQuality(instrParamShortName, 'Good');
    }

    if(objConvertedValue.LRange == null && objConvertedValue.URange == null) {
      // Should not happen, even the good range values are not available for 
      // this param. Use range to [0,0].
      console.log(`Should not happen. Param: [${instrParamShortName}] does not have 'Good' range values in stdAirQualityRange.`);

      objConvertedValue = { LRange: 0, URange: 0 };
    }

    return objConvertedValue;
  }
  
  renderParamNameBasedOnType(inParamNameType, t){
    switch(inParamNameType) {
        case AQI:
          return (<span className="parameterNameAndSymbol">{t(IDS_airQualityIndex)}</span>);
        case CAQI:
          return (<span className="parameterNameAndSymbol">{t(IDS_currentAirQualityIndex)}</span>);
        case NO2:
            return (<span className="parameterNameAndSymbol">{t(IDS_nitrogenDioxide)} (NO<sub>2</sub>)</span>);
        case O3:
            return (<span className="parameterNameAndSymbol">{t(IDS_Ozone)} (O<sub>3</sub>)</span>);
        case SO2:
            return (<span className="parameterNameAndSymbol">{t(IDS_sulphurDioxide)} (SO<sub>2</sub>)</span>);
        case VOC:
            return (<span className="parameterNameAndSymbol">{t(IDS_TotalVolatileOrgComp)} (TVOC - PPB)</span>);
        case CO:
            return (<span className="parameterNameAndSymbol">{t(IDS_carbonMonoxide)} (CO)</span>);
        case NH3:
            return (<span className="parameterNameAndSymbol">{t(IDS_Ammonia)} (NH<sub>3</sub>)</span>);
        case CO2:
            return (<span className="parameterNameAndSymbol">{t(IDS_carbonDioxide)} (CO<sub>2</sub>)</span>);
        case H2S:
            return (<span className="parameterNameAndSymbol">{t(IDS_hydrogenSulphide)} (H<sub>2</sub>S)</span>);
        case CH4:
            return (<span className="parameterNameAndSymbol">{t(IDS_Methane)} (CH<sub>4</sub>)</span>);
        case LPG:
          return (<span className="parameterNameAndSymbol">{t(IDS_LPG)}</span>);
        case PM1:
            return (<span className="parameterNameAndSymbol">{t(IDS_Dust)} (PM<sub>1</sub>)</span>);
        case PM25:
            return (<span className="parameterNameAndSymbol">{t(IDS_Dust)} (PM<sub>2.5</sub>)</span>);
        case PM10:
            return (<span className="parameterNameAndSymbol">{t(IDS_Dust)} (PM<sub>10</sub>)</span>);
        case TEMP:
            return (<span className="parameterNameAndSymbol">{t(IDS_Temperature)} (Degree)</span>);
        case HUM:
            return (<span className="parameterNameAndSymbol">{t(IDS_Humidity)} (%)</span>);
        case NH3OD:
            return (<span className="parameterNameAndSymbol">{t(IDS_Odour)} (Level)</span>);  
        case SMOKE:
          return (<span className="parameterNameAndSymbol">{t(IDS_SMOKE)}</span>);    
        case VRI:
          return (<span className="parameterNameAndSymbol">{t(IDS_VRI)} ({t(IDS_Index)})</span>);  
        case MRI:
          return (<span className="parameterNameAndSymbol">{t(IDS_MRI)} ({t(IDS_Index)})</span>);
        case WLIP:
            return (<span className="parameterNameAndSymbol">{t(IDS_WLIP)} (%)</span>);    
        case SLIP:
            return (<span className="parameterNameAndSymbol">{t(IDS_SLIP)} (%)</span>);  
        case HCHO:
          return (<span className="parameterNameAndSymbol">{t(IDS_Formaldehyde)} (PPB)</span>); 
        case O2:
          return (<span className="parameterNameAndSymbol">{t(IDS_Oxygen)} (%)</span>); 
        case PM100:
          return (<span className="parameterNameAndSymbol">{t(IDS_PM100)}</span>); 
        case NO:
          return (<span className="parameterNameAndSymbol">{t(IDS_NitricOxide)}</span>); 
        case CL:
          return (<span className="parameterNameAndSymbol">{t(IDS_Chlorine)} (PPB)</span>); 
        case CH3SH:
          return (<span className="parameterNameAndSymbol">{t(IDS_MethylMercaptan)} (PPB)</span>); 
        case NOISE:
          return (<span className="parameterNameAndSymbol">{t(IDS_Noise)} (dB)</span>); 
        case LUX:
          return (<span className="parameterNameAndSymbol">{t(IDS_LightIntensity)} (lux)</span>); 
        case UV:
          return (<span className="parameterNameAndSymbol">{t(IDS_UV)} (Index)</span>); 
        case RADON:
          return (<span className="parameterNameAndSymbol">{t(IDS_Radon)}</span>); 
        case AP:
          return (<span className="parameterNameAndSymbol">{t(IDS_AirPressure)} (hPa)</span>); 
        case WS:
          return (<span className="parameterNameAndSymbol">{t(IDS_WindSpeed)} (m/s)</span>); 
        case WD:
          return (<span className="parameterNameAndSymbol">{t(IDS_WindDirection)} (degree)</span>); 
        case DLV:
          return (<span className="parameterNameAndSymbol">{t(IDS_DaylightVisibility)} (km)</span>); 
        case RNFL:
          return (<span className="parameterNameAndSymbol">{t(IDS_Rainfall)} (mm)</span>); 
        default:
          console.error(`Unable to get ParamName. Unknown Param Type: ${inParamNameType}`);
          return (<span></span>); // Return empty tag
    }
  }

  // Function returns an array containing all the levels (like 'L1', 'L2' etc.) and their appropriate
  // upper and lower range values for the specified param (example: 'NH3OD') from
  // the stdAirQuality.
  // Returns empty array if there is any issue or if the param is not found in StdAirQualityRange.
  getAirQualityLevelAndRangesForParam = (instrParamShortName) => {

    let singleParamAirQualityInfo = 
      this.state.stdAirQualityRanges.find( (arrElement) => arrElement["MeasuredParam"] == instrParamShortName && arrElement["RangeValues"] != null );

    if(singleParamAirQualityInfo == null) {
      console.log(`Should not happen. Param [${instrParamShortName}] was not found in stdAirQualityRange.`);
      return []; // Return empty array if the Param was not found in stdAirQualityRange
    }

    let singleParamAirQualityRange = {};
    try {
      singleParamAirQualityRange = JSON.parse(singleParamAirQualityInfo.RangeValues);
    } catch {
      console.log(`Should not happen. StdAirQualityRange for Param [${instrParamShortName}] is in invalid JSON format.`);
      return []; // Return empty array if there is JSON conversion error
    }

    let singleParamLowRange = singleParamAirQualityRange[LOW]; // Not used at the moment
    let singleParamMaxRange = singleParamAirQualityRange[MAX]; // Not used at the moment
    let arrValueToAirQualityLevelMap = [
      { Level: "L0", LevelQuality: "LowSevere",     LRange: singleParamAirQualityRange.RangeValues[LOW_SEVERE_L],   URange: singleParamAirQualityRange.RangeValues[LOW_SEVERE_U]   },
      { Level: "L1", LevelQuality: "Good",          LRange: singleParamAirQualityRange.RangeValues[GOOD_L],         URange: singleParamAirQualityRange.RangeValues[GOOD_U]         },
      { Level: "L2", LevelQuality: "Satisfactory",  LRange: singleParamAirQualityRange.RangeValues[SATISFACTORY_L], URange: singleParamAirQualityRange.RangeValues[SATISFACTORY_U] },
      { Level: "L3", LevelQuality: "Moderate",      LRange: singleParamAirQualityRange.RangeValues[MODERATE_L],     URange: singleParamAirQualityRange.RangeValues[MODERATE_U]     },
      { Level: "L4", LevelQuality: "Poor",          LRange: singleParamAirQualityRange.RangeValues[POOR_L],         URange: singleParamAirQualityRange.RangeValues[POOR_U]         },
      { Level: "L5", LevelQuality: "VPoor",         LRange: singleParamAirQualityRange.RangeValues[V_POOR_L],       URange: singleParamAirQualityRange.RangeValues[V_POOR_U]       },
      { Level: "L6", LevelQuality: "HighSevere",    LRange: singleParamAirQualityRange.RangeValues[HIGH_SEVERE_L],  URange: singleParamAirQualityRange.RangeValues[HIGH_SEVERE_U]  }
    ];

    return arrValueToAirQualityLevelMap;
  }

  // Function returns the level ('L1', 'L2' etc.) for the input value of the 
  // specified param (example: 'NH3OD') from stdAirQuality.
  // Returns empty string if there is any issue or if the param is not found in StdAirQualityRange
  // or if the value could not be mapped to an appropriate level.
  getAirQualityLevelForParamAndValue = (instrParamShortName, inParamValue) => {

    let arrValueToAirQualityLevelMap = this.getAirQualityLevelAndRangesForParam(instrParamShortName);

    // No need to check size of above array as error messages already logged in the called function.
    
    // NOTE
    // Unlike the other pages (SingleDeviceData, AllDeviceData) we are using '>=' check 
    // for Lower range and '<' check for Upper range. This will ensure that a particular
    // setting will be mapped to the appropriate range even if the value does not
    // match the 'LRange' value for any of the ranges.

    // Search the level map in the reverse direction to ensure that the value does not take
    // a range which is before the actual range in which the value is supposed to be.
    let strMappedLevel = "";
    let strMappedLevelQuality = "";
    let singleValueToAirQualityLevelMap = null;
    let noOfRanges = arrValueToAirQualityLevelMap.length;
    for( let i = (noOfRanges-1); i >= 0; i-- ) {

      singleValueToAirQualityLevelMap = arrValueToAirQualityLevelMap[i];

      if( (singleValueToAirQualityLevelMap.LRange != null && inParamValue == singleValueToAirQualityLevelMap.LRange) ||
          (singleValueToAirQualityLevelMap.LRange != null && 
           singleValueToAirQualityLevelMap.URange != null && 
           inParamValue > singleValueToAirQualityLevelMap.LRange && 
           inParamValue <= singleValueToAirQualityLevelMap.URange
          ) ||
          (singleValueToAirQualityLevelMap.URange == null && 
           (inParamValue == singleValueToAirQualityLevelMap.LRange || inParamValue > singleValueToAirQualityLevelMap.LRange)
          )
      ) {
        strMappedLevel = singleValueToAirQualityLevelMap.Level;
        strMappedLevelQuality = singleValueToAirQualityLevelMap.LevelQuality;
        break; // No need to check further
      }
    }

    if(strMappedLevel.length <= 0) {
      console.log(`Should not happen. Level could not be found for Param [${instrParamShortName}].`);
    }

    return strMappedLevel;
  }

  // Function returns the ranges (Lower and Upper) for the specified level ('L1', 'L2' etc.) of the 
  // specified param (example: 'NH3OD') from stdAirQuality.
  // If there is any issue, the upper and lower range values will be null in the returned object.
  getAirQualityRangeValuesForParamAndLevel = (instrParamShortName, instrLevel) => {

    let retVal = {LRange: null, URange: null};

    let arrAirQualityLevelAndRangesForParam = this.getAirQualityLevelAndRangesForParam(instrParamShortName);
    // The above call will return empty array if Param not found

    let singleLevelRanges = arrAirQualityLevelAndRangesForParam.find( (arrElement) => arrElement["Level"] == instrLevel );
    // The above call will return undefined if Level not found in the single param AirQualityLevel array.

    if(singleLevelRanges == null) {
      console.log(`Should not happen. Param [${instrParamShortName}] or Level [${instrLevel}] was not found in stdAirQualityRange.`);
    } else {
      retVal.LRange = singleLevelRanges["LRange"];
      retVal.URange = singleLevelRanges["URange"];
    }

    return retVal;
  }

  // Function returns the ranges (Lower and Upper) for the specified level quality ('Good', 'Poor' etc.) of the 
  // specified param (example: 'NH3OD') from stdAirQuality.
  // If there is any issue, the upper and lower range values will be null in the returned object.
  getAirQualityRangeValuesForParamAndLevelQuality = (instrParamShortName, instrLevelQuality) => {

    let retVal = {LRange: null, URange: null};

    let arrAirQualityLevelAndRangesForParam = this.getAirQualityLevelAndRangesForParam(instrParamShortName);
    // The above call will return empty array if Param not found

    let singleLevelRanges = arrAirQualityLevelAndRangesForParam.find( (arrElement) => arrElement["LevelQuality"] == instrLevelQuality );
    // The above call will return undefined if LevelQuality not found in the single param AirQualityLevel array.

    if(singleLevelRanges == null) {
      console.log(`Should not happen. Param [${instrParamShortName}] or LevelQuality [${instrLevelQuality}] was not found in stdAirQualityRange.`);
    } else {
      retVal.LRange = singleLevelRanges["LRange"];
      retVal.URange = singleLevelRanges["URange"];
    }

    return retVal;
  }

  // Function returns the level ('L2', 'L3' etc) for the specified level quality ('Good', 'Moderate' etc.) of the 
  // specified param (example: 'NH3OD') from stdAirQuality.
  // If there is any issue, the returned level will be blank.
  getAirQualityLevelForParamAndLevelQuality = (instrParamShortName, instrLevelQuality) => {

    let retVal = ""; // Blank if level conversion unsuccessful

    let arrAirQualityLevelAndRangesForParam = this.getAirQualityLevelAndRangesForParam(instrParamShortName);
    // The above call will return empty array if Param not found

    let singleLevelRanges = arrAirQualityLevelAndRangesForParam.find( (arrElement) => arrElement["LevelQuality"] == instrLevelQuality );
    // The above call will return undefined if LevelQuality not found in the single param AirQualityLevel array.

    if(singleLevelRanges == null) {
      console.log(`Should not happen. Param [${instrParamShortName}] or LevelQuality [${instrLevelQuality}] was not found in stdAirQualityRange.`);
    } else {
      retVal = singleLevelRanges["Level"];
    }

    return retVal;
  }

  getSelectedLevelLongDescription = (instrParamShortName, inParamValue) => {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

    let strSelectedLevelDescription = "";

    let arrLevelSettingMessage = [];

    if( ARR_LEVEL_BASED_PARAMS.includes(instrParamShortName) ) {
      // In case of Level Based settings (Example: 'NH3OD'), specify the levels to
      // be shown in settings

      // Default level messages for most other parameters
      arrLevelSettingMessage = MAP_DEFAULT_LEVEL_SETTING_LONG_DESCRIPTION;     

      let strMappedLevel = this.getAirQualityLevelForParamAndValue(instrParamShortName, inParamValue);

      if(strMappedLevel.length > 0 && arrLevelSettingMessage[strMappedLevel] != null) {
        strSelectedLevelDescription = t(arrLevelSettingMessage[strMappedLevel]);
      } else {
        strSelectedLevelDescription = ""; // Better be blank than display wrong message
      }
  
    }

    return strSelectedLevelDescription;
  }

  renderParamAlertDescription = (instrParamShortName, inParamValue) => {

    const singleParamAirQualityRange = this.state.stdAirQualityRanges.find(param => param.MeasuredParam === NH3OD) 

    let arrValueToAirQualityLevelMap = [
      { Level: "L0", LevelQuality: "LowSevere",     LRange: singleParamAirQualityRange.RangeValues[LOW_SEVERE_L],   URange: singleParamAirQualityRange.RangeValues[LOW_SEVERE_U]   },
      { Level: "L1", LevelQuality: "Good",          LRange: singleParamAirQualityRange.RangeValues[GOOD_L],         URange: singleParamAirQualityRange.RangeValues[GOOD_U]         },
      { Level: "L2", LevelQuality: "Satisfactory",  LRange: singleParamAirQualityRange.RangeValues[SATISFACTORY_L], URange: singleParamAirQualityRange.RangeValues[SATISFACTORY_U] },
      { Level: "L3", LevelQuality: "Moderate",      LRange: singleParamAirQualityRange.RangeValues[MODERATE_L],     URange: singleParamAirQualityRange.RangeValues[MODERATE_U]     },
      { Level: "L4", LevelQuality: "Poor",          LRange: singleParamAirQualityRange.RangeValues[POOR_L],         URange: singleParamAirQualityRange.RangeValues[POOR_U]         },
      { Level: "L5", LevelQuality: "VPoor",         LRange: singleParamAirQualityRange.RangeValues[V_POOR_L],       URange: singleParamAirQualityRange.RangeValues[V_POOR_U]       },
      { Level: "L6", LevelQuality: "HighSevere",    LRange: singleParamAirQualityRange.RangeValues[HIGH_SEVERE_L],  URange: singleParamAirQualityRange.RangeValues[HIGH_SEVERE_U]  }
    ];

    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

    let paramAlertDescription = "";

    if( ARR_LEVEL_BASED_PARAMS.includes(instrParamShortName) ) {
      if(instrParamShortName == NH3OD) {

        // Below, have used both values URange as well as the difference value between each pointer on the slider to appropriately match the Alert Description
        // The reason behind using both values for match-checking is because, at initial component mount, it fetches the `URange` of the Parameter NH3OD, but the slider
        // will always take its difference value when slider is moved, thereby, need of matching paramValue with Slider's Diff-Value
        if (inParamValue == arrValueToAirQualityLevelMap[1].URange || inParamValue == 20) {
          paramAlertDescription = t(IDS_RangeAlertLevel2LD)
        } else if(inParamValue == arrValueToAirQualityLevelMap[2].LRange || inParamValue == 40) {
          paramAlertDescription = t(IDS_RangeAlertLevel3LD)
        } else if(inParamValue == arrValueToAirQualityLevelMap[3].LRange || inParamValue == 60) {
          paramAlertDescription = t(IDS_RangeAlertLevel4LD)
        } else if(inParamValue == arrValueToAirQualityLevelMap[4].LRange || inParamValue == 80) {
          paramAlertDescription = t(IDS_RangeAlertLevel5LD)
        } else if(inParamValue == arrValueToAirQualityLevelMap[5].LRange || inParamValue == 100) {
          paramAlertDescription = t(IDS_RangeAlertLevel6LD)
        } else {
          return;
        }
      }
    }

    return paramAlertDescription;

  }

  renderSelectedParamStatus = (instrParamShortName, inParamValue) => {
    
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

    const singleParamAirQualityRange = this.state.stdAirQualityRanges.find(param => param.MeasuredParam === NH3OD) 

    let arrValueToAirQualityLevelMap = [
      { Level: "L0", LevelQuality: "LowSevere",     LRange: singleParamAirQualityRange.RangeValues[LOW_SEVERE_L],   URange: singleParamAirQualityRange.RangeValues[LOW_SEVERE_U]   },
      { Level: "L1", LevelQuality: "Good",          LRange: singleParamAirQualityRange.RangeValues[GOOD_L],         URange: singleParamAirQualityRange.RangeValues[GOOD_U]         },
      { Level: "L2", LevelQuality: "Satisfactory",  LRange: singleParamAirQualityRange.RangeValues[SATISFACTORY_L], URange: singleParamAirQualityRange.RangeValues[SATISFACTORY_U] },
      { Level: "L3", LevelQuality: "Moderate",      LRange: singleParamAirQualityRange.RangeValues[MODERATE_L],     URange: singleParamAirQualityRange.RangeValues[MODERATE_U]     },
      { Level: "L4", LevelQuality: "Poor",          LRange: singleParamAirQualityRange.RangeValues[POOR_L],         URange: singleParamAirQualityRange.RangeValues[POOR_U]         },
      { Level: "L5", LevelQuality: "VPoor",         LRange: singleParamAirQualityRange.RangeValues[V_POOR_L],       URange: singleParamAirQualityRange.RangeValues[V_POOR_U]       },
      { Level: "L6", LevelQuality: "HighSevere",    LRange: singleParamAirQualityRange.RangeValues[HIGH_SEVERE_L],  URange: singleParamAirQualityRange.RangeValues[HIGH_SEVERE_U]  }
    ];

    let paramStatus = "";

    if( ARR_LEVEL_BASED_PARAMS.includes(instrParamShortName) ) {
      if(instrParamShortName == NH3OD) {

        // Below, have used both values URange as well as the difference value between each pointer on the slider to appropriately match the Alert Description
        // The reason behind using both values for match-checking is because, at initial component mount, it fetches the `URange` of the Parameter NH3OD, but the slider
        // will always take its difference value when slider is moved, thereby, need of matching paramValue with Slider's Diff-Value
        if (inParamValue == arrValueToAirQualityLevelMap[1].URange || inParamValue == 20) {
          paramStatus = t(IDS_NH3ODRangeAlertLevel2SD)
        } else if(inParamValue == arrValueToAirQualityLevelMap[2].LRange || inParamValue == 40) {
          paramStatus = t(IDS_NH3ODRangeAlertLevel3SD)
        } else if(inParamValue == arrValueToAirQualityLevelMap[3].LRange || inParamValue == 60) {
          paramStatus = t(IDS_NH3ODRangeAlertLevel4SD)
        } else if(inParamValue == arrValueToAirQualityLevelMap[4].LRange || inParamValue == 80) {
          paramStatus = t(IDS_NH3ODRangeAlertLevel5SD)
        } else if(inParamValue == arrValueToAirQualityLevelMap[5].LRange || inParamValue == 100) {
          paramStatus = t(IDS_NH3ODRangeAlertLevel6SD)
        } else {
          return;
        }
      }
    }
    return paramStatus;
  }

  getSelectedLevelShortDescription = (instrParamShortName, inParamValue) => {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

    let strSelectedLevelDescription = "";

    let arrLevelSettingMessage = [];

    if( ARR_LEVEL_BASED_PARAMS.includes(instrParamShortName) ) {
      // In case of Level Based settings (Example: 'NH3OD'), specify the levels to
      // be shown in settings

      if(instrParamShortName == NH3OD) {
        arrLevelSettingMessage = MAP_NH3OD_LEVEL_SETTING_SHORT_DESCRIPTION;
      } else {
        // Default level messages for most other parameters
        arrLevelSettingMessage = MAP_DEFAULT_LEVEL_SETTING_SHORT_DESCRIPTION;
      }

      let strMappedLevel = this.getAirQualityLevelForParamAndValue(instrParamShortName, inParamValue);

      if(strMappedLevel.length > 0 && arrLevelSettingMessage[strMappedLevel] != null) {
        strSelectedLevelDescription = t(arrLevelSettingMessage[strMappedLevel]);
      } else {
        strSelectedLevelDescription = ""; // Better be blank than display wrong message
      }
  
    }

    return strSelectedLevelDescription;
  }

  // Returns an array containing the levels (['L2', 'L3', 'L4', 'L5']) to be shown in Settings for level based
  // params (like 'NH3OD'). For all other params, returns empty array.
  getSettingLevelsForLevelBasedParams = (instrParamShortName) => {

    let arrSettingLevels = []; // Array will be blank for non level based params
    const marks = {};
    let result;

    const singleParamAirQualityRange = this.state.stdAirQualityRanges.find(param => param.MeasuredParam === NH3OD) 
    let arrValueToAirQualityLevelMap = [
      { Level: "L0", LevelQuality: "LowSevere",     LRange: singleParamAirQualityRange.RangeValues[LOW_SEVERE_L],   URange: singleParamAirQualityRange.RangeValues[LOW_SEVERE_U]   },
      { Level: "L1", LevelQuality: "Good",          LRange: singleParamAirQualityRange.RangeValues[GOOD_L],         URange: singleParamAirQualityRange.RangeValues[GOOD_U]         },
      { Level: "L2", LevelQuality: "Satisfactory",  LRange: singleParamAirQualityRange.RangeValues[SATISFACTORY_L], URange: singleParamAirQualityRange.RangeValues[SATISFACTORY_U] },
      { Level: "L3", LevelQuality: "Moderate",      LRange: singleParamAirQualityRange.RangeValues[MODERATE_L],     URange: singleParamAirQualityRange.RangeValues[MODERATE_U]     },
      { Level: "L4", LevelQuality: "Poor",          LRange: singleParamAirQualityRange.RangeValues[POOR_L],         URange: singleParamAirQualityRange.RangeValues[POOR_U]         },
      { Level: "L5", LevelQuality: "VPoor",         LRange: singleParamAirQualityRange.RangeValues[V_POOR_L],       URange: singleParamAirQualityRange.RangeValues[V_POOR_U]       },
      { Level: "L6", LevelQuality: "HighSevere",    LRange: singleParamAirQualityRange.RangeValues[HIGH_SEVERE_L],  URange: singleParamAirQualityRange.RangeValues[HIGH_SEVERE_U]  }
    ];


    if( ARR_LEVEL_BASED_PARAMS.includes(instrParamShortName) ) {
      // In case of Level Based settings (Example: 'NH3OD'), specify the levels to
      // be shown in settings
      arrSettingLevels = ARR_DEFAULT_SETTING_LEVELS;

      const step = 100 / (arrValueToAirQualityLevelMap.length - 2); // Calculate step size
      // const step = arrSettingLevels.length - 1;

      arrSettingLevels.forEach((level, index) => {
          const value = index * step;
          marks[value] = { label: level }; // Assign label to mark
      });

      result = marks;
    }

    return result;
    
    // const newLabels = this.getSettingLabels(arrSettingLevels)

    // return newLabels;
    // return arrSettingLevels;
  }

  getSettingLabels = (settingLevels) => {

    const step = 100 / (settingLevels.length - 1); // Calculate step size
    const marks = {};

    settingLevels.forEach((level, index) => {
      const value = index * step;
      marks[value] = { label: level }; // Assign label to mark
    });

    return marks;
  }

  settingSteps = (instrParamShortName) => {
    let arrSettingLevels = []; // Array will be blank for non-level-based params

    if (ARR_LEVEL_BASED_PARAMS.includes(instrParamShortName)) {
        // In case of Level Based settings (Example: 'NH3OD'), specify the levels to
        // be shown in settings
        arrSettingLevels = ARR_DEFAULT_SETTING_LEVELS;
    }

    const singleParamAirQualityRange = this.state.stdAirQualityRanges.find(param => param.MeasuredParam === NH3OD) 

      let arrValueToAirQualityLevelMap = [
        { Level: "L0", LevelQuality: "LowSevere",     LRange: singleParamAirQualityRange.RangeValues[LOW_SEVERE_L],   URange: singleParamAirQualityRange.RangeValues[LOW_SEVERE_U]   },
        { Level: "L1", LevelQuality: "Good",          LRange: singleParamAirQualityRange.RangeValues[GOOD_L],         URange: singleParamAirQualityRange.RangeValues[GOOD_U]         },
        { Level: "L2", LevelQuality: "Satisfactory",  LRange: singleParamAirQualityRange.RangeValues[SATISFACTORY_L], URange: singleParamAirQualityRange.RangeValues[SATISFACTORY_U] },
        { Level: "L3", LevelQuality: "Moderate",      LRange: singleParamAirQualityRange.RangeValues[MODERATE_L],     URange: singleParamAirQualityRange.RangeValues[MODERATE_U]     },
        { Level: "L4", LevelQuality: "Poor",          LRange: singleParamAirQualityRange.RangeValues[POOR_L],         URange: singleParamAirQualityRange.RangeValues[POOR_U]         },
        { Level: "L5", LevelQuality: "VPoor",         LRange: singleParamAirQualityRange.RangeValues[V_POOR_L],       URange: singleParamAirQualityRange.RangeValues[V_POOR_U]       },
        { Level: "L6", LevelQuality: "HighSevere",    LRange: singleParamAirQualityRange.RangeValues[HIGH_SEVERE_L],  URange: singleParamAirQualityRange.RangeValues[HIGH_SEVERE_U]  }
      ];

    // Calculate the total number of checkpoints desired (including start and end)
    // const numCheckpoints = arrValueToAirQualityLevelMap.length - 1;
    const numCheckpoints = arrValueToAirQualityLevelMap.length - 1;

    // Calculate the range between each checkpoint
    const range = 100 / (numCheckpoints - 1); // Assuming the slider range is from 0 to 100

    return range;
  }
  
  // Converts the numerical value to level to be used in Settings for level based
  // params (like 'NH3OD'). For all other params, does not change the value.
  // If level could not be found, then takes the level for 'Good'.
  // If level for 'Good' is also not found then sets the value to blank.
  convertInputIfRequiredForLevelBasedParams = (inValueToConvert, instrParamShortName) => {

    let arrSettingLevels = [null, null];

    if( ARR_LEVEL_BASED_PARAMS.includes(instrParamShortName) ) {
      // In case of Level Based settings (Example: 'NH3OD'), convert the value
      // to appropriate level.

      let convertedLevel = "";

      if(inValueToConvert[0] != null) {
        // First convert to level
        convertedLevel = this.getAirQualityLevelForParamAndValue(instrParamShortName, inValueToConvert[0]);
        // If could not convert to level, then use the level of "Good"
        if(convertedLevel.length <= 0) {
          convertedLevel = this.getAirQualityLevelForParamAndLevelQuality(instrParamShortName, "Good");
        }

        arrSettingLevels[0] = convertedLevel;
      }

      if(inValueToConvert[1] != null) {
        // Convert to level
        convertedLevel = this.getAirQualityLevelForParamAndValue(instrParamShortName, inValueToConvert[1]);
        // If could not convert to level, then use the level of "Good"
        if(convertedLevel.length <= 0) {
          convertedLevel = this.getAirQualityLevelForParamAndLevelQuality(instrParamShortName, "Good");
        }

        arrSettingLevels[1] = convertedLevel;
      }

    } else {
      arrSettingLevels = inValueToConvert; // No conversion required
    }

    return arrSettingLevels;
  }

  paramRange = (min, max) => {

    const paramRangeObj = {
      0: '0',
      [min]: `${min}`,
      [max]: `${max}`,
      100: '100'
    }

    return paramRangeObj;
  };

  handleSliderChange = (index, shortName, value) => {


    if(shortName == "WLIP" || shortName == "SLIP") {
      const lowSevereL = this.state.stdAirQualityGoodRangeObj[shortName]
    }
    const goodU = this.state.stdAirQualityGoodRangeObj[shortName]

    if(shortName == DLV) {
      this.setState(prevState => ({
        ...prevState,
        rangeMinMaxSetting: prevState.rangeMinMaxSetting.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    inputvalue: [
                      value[0] < goodU[0] ? value[0] : goodU[0],
                      value[1]
                    ],
                };
            }
            return item;
        })
    }));
    }

    if (goodU[0] && goodU[1]) {
      this.setState(prevState => ({
          ...prevState,
          rangeMinMaxSetting: prevState.rangeMinMaxSetting.map((item, idx) => {
              if (idx === index) {
                  return {
                      ...item,
                      inputvalue: [
                        value[0] > goodU[0] ? goodU[0] : value[0],
                        value[1] < goodU[1] ? goodU[1] : value[1]
                      ],
                  };
              }
              return item;
          })
      }));
    }

    if(goodU[0] == null && goodU[1] != null ) {
      this.setState(prevState => ({
        ...prevState,
        rangeMinMaxSetting: prevState.rangeMinMaxSetting.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              inputvalue: [
                null,
                value < goodU[1] ? goodU[1] : value,
              ],
            };
          }
          return item;
        })
      }));
    }
  };
  
  
  handleInputChange = (index, value) => {

    this.setState((prevState) => ({
      rangeMinMaxSetting: prevState.rangeMinMaxSetting.map((item, idx) => ({
        ...item, 
        sliderValue: idx == index ? value : item.sliderValue
      })) 
    }))
  };

  enableAlertsForDevice = () => {

      this.setState(prevState => ({
          enableAllNotifications: !prevState.enableAllNotifications,
          rangeMinMaxSetting: prevState.rangeMinMaxSetting.map(item => ({
              ...item,
              isChecked: !prevState.enableAllNotifications
          }))
      }));
  }

  renderNoAlertRangeForMinMax = (shortName, tooltipMin, tooltipMax) => {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t; 

    let marks = {};

    if(shortName) {
      marks = {
        [tooltipMin]: (
          <Tooltip placement="bottom" title={t(IDS_AlertRangePopUpInfo, tooltipMin, tooltipMax)}>
              {tooltipMin}
          </Tooltip>
        ),
        [tooltipMax]: (
          <Tooltip placement="bottom" title={t(IDS_AlertRangePopUpInfo, tooltipMin, tooltipMax)}>
              {tooltipMax}
          </Tooltip>
        )
      }
    }

    return marks;
  }
  
  renderNoAlertRange = (shortName, tooltipMax) => {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t; 

    let marks = {};
    if(shortName) {
      marks = {
        [tooltipMax]: (
          <Tooltip placement="bottom" 
            title={t(IDS_AlertValuePopUpInfo, tooltipMax)}
          >
              {tooltipMax}
          </Tooltip>
        )
      }
    }

    return marks;
  }

  rangeNoAlertRangeForReverseSlider = (shortName, tooltipMax) => {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t; 

    let marks = {};
    if(shortName) {
      marks = {
        [tooltipMax]: (
          <Tooltip placement="bottom" 
            title={t(IDS_AlertValuePopUpInfoForReverseSlider, tooltipMax)}
          >
              {tooltipMax}
          </Tooltip>
        )
      }
    }

    return marks;
  }

  renderNH3ODValueToRestrictUser = (value) => {

    const singleParamAirQualityRange = this.state.stdAirQualityRanges.find(param => param.MeasuredParam === NH3OD) 

    let arrValueToAirQualityLevelMap = [
      { Level: "L0", LevelQuality: "LowSevere",     LRange: singleParamAirQualityRange.RangeValues[LOW_SEVERE_L],   URange: singleParamAirQualityRange.RangeValues[LOW_SEVERE_U]   },
      { Level: "L1", LevelQuality: "Good",          LRange: singleParamAirQualityRange.RangeValues[GOOD_L],         URange: singleParamAirQualityRange.RangeValues[GOOD_U]         },
      { Level: "L2", LevelQuality: "Satisfactory",  LRange: singleParamAirQualityRange.RangeValues[SATISFACTORY_L], URange: singleParamAirQualityRange.RangeValues[SATISFACTORY_U] },
      { Level: "L3", LevelQuality: "Moderate",      LRange: singleParamAirQualityRange.RangeValues[MODERATE_L],     URange: singleParamAirQualityRange.RangeValues[MODERATE_U]     },
      { Level: "L4", LevelQuality: "Poor",          LRange: singleParamAirQualityRange.RangeValues[POOR_L],         URange: singleParamAirQualityRange.RangeValues[POOR_U]         },
      { Level: "L5", LevelQuality: "VPoor",         LRange: singleParamAirQualityRange.RangeValues[V_POOR_L],       URange: singleParamAirQualityRange.RangeValues[V_POOR_U]       },
      { Level: "L6", LevelQuality: "HighSevere",    LRange: singleParamAirQualityRange.RangeValues[HIGH_SEVERE_L],  URange: singleParamAirQualityRange.RangeValues[HIGH_SEVERE_U]  }
    ];

    let defaultOdourValue;

    // Below, have used both values URange as well as the difference value between each pointer on the slider to appropriately match the Alert Description
    // The reason behind using both values for match-checking is because, at initial component mount, it fetches the `URange` of the Parameter NH3OD, but the slider
    // will always take its difference value when slider is moved, thereby, need of matching paramValue with Slider's Diff-Value
    if(value == arrValueToAirQualityLevelMap[1].URange || value == 20) {
      defaultOdourValue = 20
    } else if(value == arrValueToAirQualityLevelMap[2].URange || value == 40) {
      defaultOdourValue = 40
    } else if(value == arrValueToAirQualityLevelMap[3].URange || value == 60) {
      defaultOdourValue = 60
    } else if(value == arrValueToAirQualityLevelMap[4].URange || value == 80) {
      defaultOdourValue = 80
    } else {
      defaultOdourValue = 100
    }

    return defaultOdourValue;
  }

  render() {
    const renderParamNameBasedOnType = this.renderParamNameBasedOnType;
    const handleAllCheck = this.handleAllCheck;

    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;  
    let ownerOfTrackedDevices = appRelevantDataContextValue.devicesToTrack.ownerOfTrackedDevices;
    let HasSelectedNodeDevices = appRelevantDataContextValue.selectedNodeInfo.hasDevc;
    let IsSelectedNodeDevices = appRelevantDataContextValue.selectedNodeInfo.isDevc;
    let SelectedNodeDeviceType = appRelevantDataContextValue.selectedNodeInfo.SelectedNodeDeviceType;

    return (

      (this.state.selectedTreeNodeID == null || this.state.selectedTreeNodeID.length <=0)
      ?
          <div className="sddSingleParam">
              <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8 ">
                      {t(IDS_InfoMsgForTreeNodeMissingInSettingPg)}
              </div>
          </div>
      :
      (this.state.selectedTreeNodeID.length > 0 && this.state.selectedTreeNodeID == NO_DEVC_FOUND_FOR_LOGGED_IN_USER)  
      ?
        <div className="sddSingleParam">
            <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8 fs-5 d-flex justify-content-center align-items-center">
                  {t(IDS_GeneralUser)}
                <div style={{margin:"0.5rem"}}>
                  <VcSetupDevice/>
                </div>               
            </div>
        </div>
      :
      //  if the selected node itself is not the device
      // then show default msg to the user.
      ((!HasSelectedNodeDevices && !IsSelectedNodeDevices) || HasSelectedNodeDevices)
      ?
        <div className=" sddSingleParam">
            <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8 "> 
                  {t(IDS_DefInfoMsgForTreeNodeInSettingPg, this.state.selectedTreeNodeTitle)}
            </div>
        </div>
      :
      // if the selected node is PFC Device
      // then show default msg to the user.
      (SelectedNodeDeviceType != null && SelectedNodeDeviceType.length > 0 && SelectedNodeDeviceType == DEVICE_TYPE_PFC)
      ?
        <div className=" sddSingleParam ">
            <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8 "> 
                {t(IDS_InfoMsgForPFCDevicesInSettingPg, this.state.selectedTreeNodeTitle)}
            </div>
          </div>
      :
      // //If the dveice has been blocked due to non-payment if some cost.
      (this.state.isDeviceBlocked == true)
      ?
      <div className="sddSingleParam" >
        <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8 ">
            {t(IDS_DevcBlockedDueToNonPaymentOfSomeCost)}
        </div>
      </div>
      :
      <div className="w-100 flex-center flex-col">
         <div className='py-3 w-100'>
            <span className='text-left flex align-items-center gap-1'>
              <small>{this.props.fullPath}</small>
              <small className='selectedNodeTitle' style={{fontWeight:"600"}}>
                  {this.state.selectedTreeNodeTitle}
              </small>
          </span>
        </div>
        
        <div className="row setting-popup" style={{ backgroundColor: "rgb(252, 254, 255)", borderRadius: "15px", width: "100%" }}>
          <div className="container col-lg-12 DeviceNameOfSD mt-1">
            {/* Show blocked Devices Data to Production/Support Users. */}
            { this.state.bCheckDeviceBlockedStatus == true && this.state.havingPvgToViewBlockedDeviceData == true ?
                <div style={{color:"green", marginTop:"0.1rem", fontSize:"0.8rem"}}>Note: The Device is blocked or Rental Period is expired. Production/Support Team can view the data.</div>
            : null
            }
            {/* {this.state.selectedTreeNodeTitle} */}
            {this.state.NoPrivilegeForChangeSettings == true ? 
              <div style={{color: 'var(--errorColor)', fontSize:"0.8rem"}} >{t(IDS_ReadOnlyAlertSettingMsg1)}<br/>{t(IDS_ReadOnlyAlertSettingMsg2)}
                {ownerOfTrackedDevices == PROD_DEVC_OWNER_USER_ID ? 
                // Below Message only display when CRM Track Device feature use for tracking a Production Device(s) and 
                // if owner of Tracked Device(s) is prod.devc.owner@viliso.in.
                // Note: Below Message only display when Setting page invoke from CRM Track Devices feature so we don't need 
                // language translation for below Message. Because in CRM language translation is not implimentated.
                  <p>These devices are under Production Team. You need to login as 'prod.devc.owner@viliso.in' to change the settings.</p> 
                :
                  <p></p>
                }
              </div> 
            :
              <div></div>
            }
          </div>
          {this.state.rangeMinMaxSetting.length <= 0 && 
              <p style={{color: 'var(--errorColor)', marginLeft:'15%'}} >{this.state.errors.others}</p>}
                
          {this.state.rangeMinMaxSetting.length > 0 &&
            <div>
              <div className="container col-lg-12 border rounded-5 mb-3">   
                <form onSubmit={(e) => this.onNotificationSubmit("updateSettings", e)}>
                  <div className="d-flex justify-content-between align-items-center my-4 p-2 border rounded-4" style={{ backgroundColor: "#f2f4f5" }}>
                    <span className="textspanEnableAlerts fw-bold">{t(IDS_EnableAlerts)} </span>
                    <Switch 
                      className="checkicon "
                      name="enableAllNotifications" 
                      style={this.state.enableAllNotifications ? { backgroundColor: "var(--secondaryColor)", height: "20px" } : {height: "20px"}} 
                      checked={this.state.enableAllNotifications} 
                      onChange={this.enableAlertsForDevice}
                      checkedChildren={<IoCheckmark />} 
                      unCheckedChildren={<RxCross2 className='antdSwitchCrossBtn'/>} 
                    />
                  </div>
                  {
                    this.state.rangeMinMaxSetting.map((singleValue, index )=> {
                      if(!singleValue.AlertBased) {
                        return (
                          <div key={singleValue.shortName + '-' + index} className='mx-4 p-1'>
                            <div className='d-flex align-items-center justify-content-between'>
                              <div>{renderParamNameBasedOnType(singleValue.shortName, t)}</div>

                              <div className='d-flex align-items-center'>
                                <span className='me-2'>{t(IDS_EnableAlertsForParam)}</span>
                                <Switch 
                                  name="enableAllNotifications" 
                                  style={singleValue.isChecked ? { backgroundColor: "var(--secondaryColor)", height: "20px" } : {height: "20px"}} 
                                  checked={singleValue.isChecked}
                                  onChange={() => handleAllCheck(index)}
                                  checkedChildren={<IoCheckmark />} 
                                  unCheckedChildren={<RxCross2 className='antdSwitchCrossBtn'/>} 
                                />
                              </div>
                            </div>
      
                            {
                              (singleValue.shortName != NH3OD && (singleValue.inputvalue[0] == null || singleValue.inputvalue[1] == null)) && singleValue.shortName != DLV &&
                                <div className="d-flex align-items-center justify-content-between mt-4">
                                  <Slider 
                                    className='valueBasedSlider'
                                    min={singleValue.min}
                                    max={singleValue.max}
                                    disabled={!singleValue.isChecked} // Slider Disabled if Toggle-OFF
                                    defaultValue={singleValue.shortName == DLV ? singleValue.inputvalue[0] : singleValue.inputvalue[1]}
                                    value={singleValue.shortName == DLV ? singleValue.inputvalue[0] : singleValue.inputvalue[1]}
                                    tooltip={{
                                      formatter: formatterForMinMax, // Use the formatter function
                                    }}
                                    onChange={(value) => this.handleSliderChange(index, singleValue.shortName, value)} // Pass index and value to handleSliderChange
                                    marks={this.renderNoAlertRange(singleValue.shortName, singleValue.tooltipMax)}
                                  /> 

                                  <span style={{marginLeft: "0.3rem", marginRight: "0.5rem", fontSize: "0.9rem", marginTop: "-1.1rem"}}>{t(IDS_Value)}:</span>
                                  <InputNumber
                                    className="valueBasedInput"
                                    value={singleValue.inputvalue[1]}
                                    disabled={true}
                                  />
                                </div>
                            }
      
                            {
                              (singleValue.inputvalue[0] != null && singleValue.inputvalue[1] != null) && singleValue.shortName != DLV &&
                                <div className="mt-4 d-flex justify-content-between" style={{marginTop: "0.5rem"}}>
                                  <div style={{marginLeft: "0.5rem", fontSize: "0.9rem"}}>{t(IDS_Min)}:&nbsp;
                                    <InputNumber
                                      className="minMaxInput"
                                      min={singleValue.inputvalue[0]}
                                      value={singleValue.inputvalue[0]}
                                      disabled={true}
                                    />
                                  </div>
      
                                  <Slider range
                                    className='rangeBasedSlider'
                                    min={singleValue.min}
                                    max={singleValue.max}
                                    onChange={value => this.handleSliderChange(index, singleValue.shortName, value)}
                                    defaultValue={[singleValue.inputvalue[0], singleValue.inputvalue[1]]}
                                    value={[singleValue.inputvalue[0], singleValue.inputvalue[1]]}
                                    disabled={!singleValue.isChecked}
                                    marks={this.renderNoAlertRangeForMinMax(singleValue.shortName, singleValue.tooltipMin, singleValue.tooltipMax)} // Display marks at 5 and 20
                                    tooltip={{
                                      formatter: formatterForValueTag, // Use the formatter function
                                    }}
                                  />
                                  
                                  <div style={{marginRight: "0.5rem", fontSize: "0.9rem"}}>{t(IDS_Max)}:&nbsp;
                                    <InputNumber
                                      className="minMaxInput"
                                      max={singleValue.inputvalue[1]}
                                      value={singleValue.inputvalue[1]}
                                      disabled={true} 
                                    />
                                  </div>
                                </div>
                            }
      
                            { 
                              ARR_LEVEL_BASED_PARAMS.includes(singleValue.shortName)
                                ?
                                <div className='d-flex justify-content-center' style={{fontSize: "0.75rem", marginBottom: "-2.5rem"}}>
                                  <span>  
                                    {this.renderParamAlertDescription(singleValue.shortName, singleValue.inputvalue[1])} <br/>
                                    {this.renderSelectedParamStatus(singleValue.shortName, singleValue.inputvalue[1])}
                                  </span>
                                </div>
                                : <div/>
                            }
                              
                            <div className={ ((singleValue.inputvalue[0] == null || singleValue.inputvalue[1] == null) && singleValue.shortName == NH3OD) ? "mt-5" : "mt-4"}>
                              {
                                singleValue.shortName == NH3OD &&
                                  // ----> For NH3OD ----->
                                  <Slider 
                                    className='levelBasedSlider'
                                    marks={this.getSettingLevelsForLevelBasedParams(singleValue.shortName)} // Labels for Each pointer of the Slider
                                    included={true} // Fills the color till the selected pointer
                                    step={this.settingSteps(singleValue.shortName)} // Steps between each pointer 
                                    value={this.renderNH3ODValueToRestrictUser(singleValue.inputvalue[1])} // Set the Value of the Slider
                                    tooltip={{ open: false }} // Renders the Tooltip of the Selected Value on the Slider & is set as OFF
                                    disabled={!singleValue.isChecked} // Slider Disabled if Toggle-OFF
                                    onChange={(value) => this.handleSliderChange(index, singleValue.shortName, value)} // Pass index and value to handleSliderChange
                                  />
                              }
                            </div>

                            { singleValue.shortName == DLV && 
                                // ----> For DLV ----->
                                <div className="d-flex align-items-center justify-content-between mt-4">
                                  <Slider range
                                    className="DLVSlider"
                                    min={singleValue.min}
                                    max={singleValue.max}
                                    onChange={value => this.handleSliderChange(index, singleValue.shortName, value)}
                                    defaultValue={[singleValue.inputvalue[0], singleValue.max]}
                                    value={[singleValue.inputvalue[0], singleValue.max]}
                                    disabled={!singleValue.isChecked}
                                    marks={this.rangeNoAlertRangeForReverseSlider(singleValue.shortName, singleValue.tooltipMin)}
                                    tooltip={{
                                      formatter: formatterForReverseValueTag,
                                    }}
                                  />

                                  <span style={{marginLeft: "0.3rem", marginRight: "0.5rem", fontSize: "0.9rem", marginTop: "-1.1rem"}}>{t(IDS_Value)}:</span>
                                  <InputNumber
                                    className="reverseSliderInput"
                                    value={singleValue.inputvalue[0]}
                                    disabled={true}
                                  />
                                </div>
                            }
                              
                            <div className='d-flex justify-content-center'>
                                <hr style={{ marginTop: "1%", width: "95%", opacity: "0.1" }} />
                            </div>
                          </div>
                        )
                      }
                    })
                  }

                  {this.state.NoPrivilegeForChangeSettings == true ?
                    <div/> :
                    <div className='mt-1'>
                      <button className="updateButton rounded-4 fw-bold" 
                        type="submit"> {t(IDS_Update)}
                      </button>
                    </div>
                  } 
                </form>

                {this.state.NoPrivilegeForChangeSettings == true ?
                  <div/> :
                    <div 
                      className="d-flex align-items-center justify-content-end p-2 border rounded-4" 
                      style={{ backgroundColor: "#f2f4f5", marginTop: "2.6rem", marginBottom: "2rem" }}
                    >
                    <span className="textspanmain fw-bold" style={{ fontSize: "0.83rem" }}>{t(IDS_ResetDefaultTitle)}</span>
                      <button className="resetButton rounded-3 fw-bold my-0 mx-2" 
                        onClick={(e) => this.onNotificationSubmit("resetSettings", e)}
                      > {t(IDS_Reset)}
                      </button>
                    </div>
                } 
              </div> 
            </div>
          }                      
      </div>
    </div>
  
    );
  }
}

VcNotificationSetting.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcNotificationSetting;